@import './main.scss';

@import './common-form.scss';
@import './confirm-form.scss';
@import './login.scss';
@import './modal.scss';
@import './ReactDock.scss';
@import './reactTags.scss';
@import './reactTagsPDF.scss';
@import './sliderToggle.scss';


@import "react-datepicker/dist/react-datepicker";
@import './chakra-datepicker.scss';

@import "yet-another-react-lightbox/styles.css";
@import "yet-another-react-lightbox/plugins/thumbnails.css";
@import "yet-another-react-lightbox/plugins/counter.css";
@import "yet-another-react-lightbox/plugins/captions.css";
